import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faFacebook, faPatreon, faPaypal, faYoutube } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #111;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 15px;
                color: #555;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #04e5e5;
                }
            }
        `
        return (
            <FooterMain>
                <SocialIcons>
                    <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com/channel/UCsjHeh3MRutKOM8f3exzbIg/featured')} />
                    <FontAwesomeIcon icon={faFacebook} className="social_icon" onClick={() => window.open('https://www.facebook.com/SquadQuebec')} />
                    <FontAwesomeIcon icon={faDiscord} className="social_icon" onClick={() => window.open('https://discord.squadquebec.ca')} />
                    <FontAwesomeIcon icon={faPatreon} className="social_icon" onClick={() => window.open('https://www.patreon.com/squadquebec')} />
                    <FontAwesomeIcon icon={faPaypal} className="social_icon" onClick={() => window.open('https://paypal.me/squadquebec')} />
                </SocialIcons>
            </FooterMain>
        )
    }
}
export default Footer
